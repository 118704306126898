export default function reduce (prevState, action) {
  if ([
    'GET_USER'
  ].includes(action.type)) {
    return {
      ...prevState,
      loading: {
        message: 'Loading user data'
      }
    };
  }

  if ([
    'LOGIN',
  ].includes(action.type)) {
    return {
      ...prevState,
      loading: {
        message: 'Logging In'
      }
    };
  }

  if ([
    'CREATE_USER',
  ].includes(action.type)) {
    return {
      ...prevState,
      loading: {
        message: 'Creating user'
      }
    };
  }

  if ([
        'CREATE_USER_FAIL',
        'LOGIN_FAIL',
        'LOGOUT_SUCCESS',
        'AUTH_INVALID',
        'GET_USER_FAIL'
      ].includes(action.type)
      ) {
    return {
      ...prevState,
      loading: null,
      projection: {},
      flags: {},
      household: {},
      moneyFlows: [],
      transactions: [],
      tasks: [],
      accounts: [],
      previous: {},
      newCreatedId: undefined,
      currentlyEditing: {},
      errors: null,
      user: {
        loggedIn: false,
        hasConnectedSpreadsheet: false
      }
    };
  }
  

  if ([
    'CREATE_USER_SUCCESS',
    'LOGIN_SUCCESS',
    'GET_USER_SUCCESS'
  ].includes(action.type)) {
    const { user: userData = {} } = action.payload.data;
    return {
      ...prevState,
      loading: null,
      user: {
        timestamp: new Date().getTime(),
        id: userData.id,
        loggedIn: true,
        hasConnectedSpreadsheet: userData.hasConnectedSpreadsheet
      }
    };
  }

  return prevState;
}
export default function reduce (prevState, action) {
  if (action.type === 'SET_LOADING') {
    return {
      ...prevState,
      loading: {
        message: action.payload.message
      }
    };
  }

  if (action.type === 'CLEAR_ERROR') {
    return { ...prevState, loading: null };
  }
  return prevState;
}
export function clearError() {
  return {
    type: 'CLEAR_ERROR'
  };
}

export function setError(error) {
  return {
    type: 'SET_ERROR',
    payload: { error }
  };
}
export default function reduce (prevState, action) {
  if (action.type === 'UPDATE_TIME') {
    const { time: newTime = {} } = action.payload;
    return {
      ...prevState,
      time: {
        ...prevState.time,
        ...newTime
      }
    };
  }
  return prevState;
}
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import authActions from 'src/actions/auth';
import Dashboard from './views/dashboard';
import { hoursAgo } from 'src/helpers/date';
import { isBefore } from 'date-fns';

const mapStateToProps = (state) => {
  const { user = {} } = state.default;

  return { user };
};

const mapDispatchToProps = dispatch => ({
  getUser: () => dispatch(authActions.getUser())
});

export const App = props => {
  useEffect(() => {
    const lastUpdated = props.user.timestamp ? new Date(props.user.timestamp) : false;
    if (props.user.loggedIn) {
      if (!lastUpdated || isBefore(lastUpdated, hoursAgo(1))) {
        props.getUser();
      }
    }
  }, []);

  return (<Dashboard {...props} />);
};

App.propTypes = {
  user: PropTypes.shape({
    loggedIn: PropTypes.bool
  }).isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

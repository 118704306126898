import _ from 'lodash';
import { updateMoneyFlowsWithoutUpdatingTransactions } from 'src/helpers/budget';

export default function reduce (prevState, action) {
  if (action.type === 'ADD_PLACEHOLDER_ACCOUNT') {
    const { newAccount } = action.payload;
    return {
      ...prevState,
      accounts: [
        newAccount,
        ...prevState.accounts
      ]
    };
  }

  if (action.type === 'DELETE_PLACEHOLDER_ACCOUNT') {
    return {
      ...prevState,
      accounts: prevState.accounts.filter(i => i.id)
    };
  }

  if ([
    'CREATE_ACCOUNT',
    'UPDATE_ACCOUNT',
    'DELETE_ACCOUNT'
  ].includes(action.type)) {
    return {
      ...prevState,
      loading: {
        message: 'Saving changes...'
      }
    };
  }
  if (action.type === 'CREATE_ACCOUNT_SUCCESS') {
    const newAccount = _.get(action, 'payload.data.account', {});
    const updatedMoneyFlows = _.get(action, 'payload.data.updatedMoneyFlows', []);
    const moneyFlows = updateMoneyFlowsWithoutUpdatingTransactions(updatedMoneyFlows, prevState.moneyFlows);

    return {
      ...prevState,
      loading: null,
      accounts: prevState.accounts.map(a => {
        if (!a.id) {
          return newAccount;
        }
        return a;
      }),
      moneyFlows,
      currentlyEditing: {}
    };
  }

  if (action.type === 'UPDATE_ACCOUNT_SUCCESS') {
    const updatedAccount = _.get(action, 'payload.data.account', {});
    const updatedMoneyFlows = _.get(action, 'payload.data.updatedMoneyFlows', []);
    const moneyFlows = updateMoneyFlowsWithoutUpdatingTransactions(updatedMoneyFlows, prevState.moneyFlows);
    return {
      ...prevState,
      loading: null,
      moneyFlows,
      accounts: prevState.accounts.map(a => {
        if (a.id === updatedAccount.id) {
          return updatedAccount;
        }
        return a;
      })
    };
  }

  if (action.type === 'DELETE_ACCOUNT_SUCCESS') {
    const deletedAccountId = _.get(action, 'payload.data.deletedAccountId', {});
    // when an account is deleted, money flows are unlinked
    const unlinkedMoneyFlows = _.get(action, 'payload.data.updatedMoneyFlows', []);
    const moneyFlows = updateMoneyFlowsWithoutUpdatingTransactions(unlinkedMoneyFlows, prevState.moneyFlows);
    return {
      ...prevState,
      loading: null,
      moneyFlows,
      accounts: prevState.accounts.filter(a => a.id !== deletedAccountId),
      currentlyEditing: {}
    };
  }

  return prevState;
}
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import theme from 'src/ui/styles/theme';
import styled from 'react-emotion';
import * as loadingActions from 'src/actions/loading';
import ReactLoading from 'react-loading';

const mapStateToProps = state => {
  let { loading } = state.default;
  return { loading };
};

const mapDispatchToProps = dispatch => ({
  clearLoading: () => dispatch(loadingActions.clearLoading())
});

const LoadingBanner = props => {
  if (!props.loading) {
    return null;
  }

  return (
    <FloatingInput>
      <LoadingSticker>
      <ReactLoading type={'spokes'} color={theme.colors.neonTheme.yellow} height={15} width={15} />
      <LoadingMessage>{props.loading.message}</LoadingMessage>
      </LoadingSticker>
    </FloatingInput>
  );
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadingBanner));

const FloatingInput = styled('div')`
  position: fixed;
  z-index: 2;
  left: 0;
  width: 100%;
  padding: 10px;
  top: 0;
`;

const LoadingSticker = styled('div')`
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: auto;
  background-color: ${theme.colors.neonTheme.pink};
  color: ${theme.colors.White};
  padding: 5px 15px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px -2px rgba(0,0,0,0.3);
`;

const LoadingMessage = styled('span')`
  display: inline-block;
  padding-left: ${theme.spacing.small}px;
  font-size: ${theme.fontSizes.xsmall2};
  font-family: ${theme.fonts.light};
`;
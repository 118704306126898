import React, { Component } from 'react';
import theme from 'src/ui/styles/theme';
import styled from 'react-emotion';
import { Route, Redirect, Switch } from 'react-router-dom';

import { connect } from 'react-redux';


import { retry } from 'src/helpers/lazyload';
import LoadingFallback from 'src/views/loadingFallback';
import ErrorBanner from 'src/views/errorBanner';
import LoadingBanner from 'src/views/loadingBanner';

// lazy load: other routers to avoid loading screen flashes when browsing
const Login = React.lazy(() => retry(() => import('./users/login')));
const Logout = React.lazy(() => retry(() => import('./users/logout')));
const Register = React.lazy(() => retry(() => import('./users/register')));
const Summary = React.lazy(() => retry(() => import('./summary')));
const Projections = React.lazy(() => retry(() => import('./projections/index')));
const YearlyProjection = React.lazy(() => retry(() => import('./projections/year')));
const MonthlyProjection = React.lazy(() => retry(() => import('./projections/month')));
// const Onboarding = React.lazy(() => retry(() => import('./onboarding/index')));
// const Scenario = React.lazy(() => retry(() => import('./scenarios/index')));
// const EditScenario = React.lazy(() => retry(() => import('./scenarios/editScenario')));
// const CreateScenarioEvents = React.lazy(() => retry(() => import('./scenarios/createScenarioEvents')));
// const EditScenarioEvents = React.lazy(() => retry(() => import('./scenarios/editScenarioEvents')));
const ComingSoon = React.lazy(() => retry(() => import('./comingSoon')));
const BudgetRoutes = React.lazy(() => retry(() => import('./budget/routes')));

const mapStateToProps = state => {

  let { projection = {}, user = {} } = state.default;
  return { projection, user };
};

class Dashboard extends Component {
  // prob dont use shouldComponentUpdate
  // but couldn't figure out how to get memo to work here :(
  shouldComponentUpdate(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      return true;
    }
    if (this.props.user.loggedIn !== nextProps.location.loggedIn) {
      return true;
    }
    return false;
  }

  render() {
    const { projection, user } = this.props;

    const onAuthRoute = [
      '/login',
      '/logout',
      '/register'
    ].includes(this.props.location.pathname);

    const onOnboardRoute = [
      /\/onboard/
    ].some(regex => regex.test(this.props.location.pathname));

    const onBudgetRoute = [
      /\/budget/
    ].some(regex => regex.test(this.props.location.pathname));

    // mostly everything should be on `/budget`
    // but ideally we can move everything in `/budget` to root level
    if (onBudgetRoute && user.loggedIn) {
      return (
        <React.Fragment>
          <React.Suspense fallback={<LoadingFallback loadingText={'Loading...'} />}>
            <BudgetRoutes location={this.props.location} />
          </React.Suspense>
        </React.Fragment>
      );
    }

    return (
      <div>
        <ErrorBanner />
        <LoadingBanner />
        <MainContent>
          <Columns>
            <React.Suspense fallback={<LoadingFallback loadingText={'Loading...'} />}>
              <Switch>
                {!user.loggedIn && !onAuthRoute && <Redirect to={'/login'} />}
                {onOnboardRoute && user.loggedIn && <Redirect to={'/budget/dashboard'} />}
                <Route path='/coming-soon' component={ComingSoon} />
                <Route path='/login' component={Login} />
                <Route path='/logout' component={Logout} />
                <Route path='/register' component={Register} />
                <Route path='/*'>
                  <Redirect to={'/budget'} />
                </Route>
              </Switch>
            </React.Suspense>
          </Columns>
        </MainContent>
      </div>
    );
  }
}

export default connect(
  mapStateToProps
)(Dashboard);

const Flex = styled('div')`
  display: flex;
`;

const MainContent = styled(Flex)`
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.tabletUp} {
    padding-top: ${props => props.extraPadding ? theme.spacing.standard * 4 : theme.spacing.standard * 3}px;
    width: 90vw;
    max-width: 1000px;
  }

  ${theme.breakpoints.tabletDown} {
    flex-direction: column;
    padding-bottom: ${theme.spacing.standard * 4}px;
  }
`;

const Columns = styled(Flex)`
  flex-direction: column;
  padding: ${theme.spacing.standard}px;

  ${theme.breakpoints.tabletDown} {
    padding: ${theme.spacing.small}px;
    min-height: 80vh;
    justify-content: center;
  }
`;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import theme from 'src/ui/styles/theme';
import styled from 'react-emotion';
import * as errorActions from 'src/actions/error';

const mapStateToProps = state => {
  let { error } = state.default;
  return { error };
};

const mapDispatchToProps = dispatch => ({
  clearError: () => dispatch(errorActions.clearError())
});

const ErrorBanner = props => {
  if (!props.error) {
    return null;
  }

  setTimeout(() => {
    props.clearError();
  }, 8000);

  return (
    <FloatingInput>
      ERROR! {JSON.stringify(props.error.message)}
    </FloatingInput>
  );
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorBanner));

const FloatingInput = styled('div')`
  position: fixed;
  z-index: 2;
  left: 0;
  width: 100%;
  background-color: ${theme.colors.Red};
  padding: 10px;

  a {
    text-align: center;
  }

  ${theme.breakpoints.tabletUp} {
    top: 0;
  }

  ${theme.breakpoints.tabletDown} {
    bottom: 0;
    font-size: ${theme.fontSizes.xsmall2};
  }
`;
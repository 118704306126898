import _ from 'lodash';
import { formatFlow, updateMoneyFlowsState, unlinkTransactions } from '../helpers/budget';

export default function reduce (prevState, action) {
  if (action.type === 'ADD_PLACEHOLDER_MONEY_FLOW') {
    const { newMoneyFlow } = action.payload;
    return {
      ...prevState,
      moneyFlows: [
        newMoneyFlow,
        ...prevState.moneyFlows
      ]
    };
  }

  if (action.type === 'DELETE_PLACEHOLDER_MONEY_FLOW') {
    return {
      ...prevState,
      moneyFlows: prevState.moneyFlows.filter(i => i.id)
    };
  }

  if ([
    'UPDATE_MONEY_FLOW',
    'BULK_UPDATE_MONEY_FLOWS',
    'CREATE_MONEY_FLOW',
    'DELETE_MONEY_FLOW'
  ].includes(action.type)) {
    return {
      ...prevState,
      loading: {
        moneyflow: 'saving',
        message: 'Saving changes...'
      }
    };
  }

  if (action.type === 'UPDATE_MONEY_FLOW_SUCCESS') {
    const updatedMoneyFlow = _.get(action, 'payload.data.moneyFlow', {});
    const {
      moneyFlows,
      transactions
    } = updateMoneyFlowsState({
      updatedMoneyFlows: [updatedMoneyFlow],
      prevMoneyFlows: prevState.moneyFlows,
      prevTransactions: prevState.transactions
    });
    return {
      ...prevState,
      loading: null,
      moneyFlows,
      transactions
    };
  }

  if (action.type === 'BULK_UPDATE_MONEY_FLOWS_SUCCESS') {
    const updatedMoneyFlows = _.get(action, 'payload.data.moneyFlows', {});
    const {
      moneyFlows,
      transactions
    } = updateMoneyFlowsState({
      updatedMoneyFlows,
      prevMoneyFlows: prevState.moneyFlows,
      prevTransactions: prevState.transactions
    });
    return {
      ...prevState,
      loading: null,
      moneyFlows,
      transactions
    };
  }

  if (action.type === 'CREATE_MONEY_FLOW_SUCCESS') {
    const newMoneyFlow = _.get(action, 'payload.data.moneyFlow', {});
    return {
      ...prevState,
      loading: null,
      currentlyEditing: {},
      moneyFlows: prevState.moneyFlows.map(f => {
        if (!f.id && f.type === newMoneyFlow.type) {
          return formatFlow(newMoneyFlow, 0);
        }
        return f;
      })
    };
  }

  if (action.type === 'DELETE_MONEY_FLOW_SUCCESS') {
    const deletedMoneyFlowId = _.get(action, 'payload.data.deletedMoneyFlowId', {});
    // when an account is deleted, money flows are unlinked
    const unlinkedTransactions = _.get(action, 'payload.data.updatedTransactions', []);
    const transactions = unlinkTransactions(unlinkedTransactions, prevState.transactions);
    return {
      ...prevState,
      loading: null,
      transactions,
      moneyFlows: prevState.moneyFlows.filter(f => f.id !== deletedMoneyFlowId),
      currentlyEditing: {}
    };
  }

  if (action.type === 'ADJUST_COMPARE_FILTERS') {
    const key = action.payload.key;
    const value = action.payload.value;
    return {
      ...prevState,
      compareFilters: {
        ...(prevState.compareFilters || {}),
        [key]: value
      }
    };
  }
  return prevState;
}

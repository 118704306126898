function register (email, password, profile) {
  return {
    types: ['CREATE_USER', 'CREATE_USER_SUCCESS', 'CREATE_USER_FAIL'],
    payload: {
      request: {
        url: '/auth/register',
        method: 'post',
        data: {
          user: {
            email, 
            password,
            profile
          }
        }
      }
    }
  };
}

function getUser() {
  return {
    types: ['GET_USER', 'GET_USER_SUCCESS', 'GET_USER_FAIL'],
    payload: {
      request: {
        url: '/user/profile',
        method: 'get'
      }
    }
  };
}

function login (email, password) {
  return {
    types: ['LOGIN', 'LOGIN_SUCCESS', 'LOGIN_FAIL'],
    payload: {
      request: {
        url: '/auth/login',
        method: 'post',
        data: {
          user: {
            email,
            password
          }
        }
      }
    }
  };
}

function logout () {
  return {
    types: ['LOGOUT', 'LOGOUT_SUCCESS', 'LOGOUT_FAIL'],
    payload: {
      request: {
        url: '/auth/logout',
        method: 'delete'
      }
    }
  };
}

module.exports = { login, register, logout, getUser };
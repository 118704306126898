import {
  format,
  subYears,
  isWithinInterval,
  parseISO,
  subDays,
  subHours,
  getDaysInMonth
} from 'date-fns';

export const FORMATS = {
  DOB: `yyyy-MM-dd`
};

export function yearsAgo (numYears) {
  return subYears(new Date(), numYears).getFullYear();
}

export function hoursAgo (numHours) {
  return subHours(new Date(), numHours);
}

export function daysLeftInMonth() {
  const today = new Date();
  const daysInMonth = getDaysInMonth(today);
  return daysInMonth - today.getDate();
}

export function daysAgo (numDays) {
  return subDays(new Date(), numDays);
}

export function dateWithinYears (stringDate, startYear, endYear) {
  return dateIsWithin(stringDate, `01/01/${startYear}`, `12/31/${endYear}`);
}

export function dateIsWithin (stringDate, stringStart, stringEnd) {
  const date = toDate(stringDate);
  const start = toDate(stringStart);
  const end = toDate(stringEnd);

  return isWithinInterval(date, { start, end });
}

export function isValidDateLength (date) {
  if (typeof date !== 'string') {
    date = date.toString();
  }
  return date.trim().length === 10;
}

export function toDobString (date) {
  return format(date, FORMATS.DOB);
}

/**
 * To reduce library size and to be compatiable with ISO date-fns v2 only supports parsing date
 * strings in ISO format. We continue to use date strings in non-ISO format (e.g. mm/dd/yyyy).
 * This helper function gives us the flexibility to continue supporting non-ISO formatted date strings
 * while using the latest version of date-fns
 */
export function toDate (dateString) {
  if (dateString === undefined) {
    return new Date();
  }

  /**
   * must use parseISO so that we use system timezone when parsing 'yyyy-MM-dd' strings.
   * Otherwise timezone is defaulted to UTC which gives us a date that is off
   * by 1-day when we try to format it.
   */
  let date = parseISO(dateString);

  // @ts-ignore
  // eslint-disable-next-line eqeqeq
  if (date == 'Invalid Date') {
    date = new Date(dateString);
  }

  return date;
}

export function toDateString (date) {
  return format(date, 'MM/dd/yyyy');
}

export function toDateTimeString(date) {
  return format(toDate(date), 'MM/dd/yyyy hh:mm');
}

export function toDayMonthTimestampString(date) {
  return format(toDate(date), 'MM/dd hh:mm');
}

const monthStrings = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const monthAbbr = {
  'January': 'Jan',
  'February': 'Feb',
  'March': 'Mar',
  'April': 'Apr',
  'May': 'May',
  'June': 'Jun',
  'July': 'Jul',
  'August': 'Aug',
  'September': 'Sep',
  'October': 'Oct',
  'November': 'Nov',
  'December': 'Dec'
};

export function getMonth (date) {
  let monthIndex;

  if (typeof date === 'number') {
    monthIndex = date;
  } else {
    monthIndex = toDate(date).getUTCMonth();
  }

  return monthStrings[monthIndex];
}

export function getMonthAbbr (date) {
  const month = getMonth(date);
  return monthAbbr[month];
}

export function getMonthIndex (month) {
  return monthStrings.indexOf(month);
}

export function getLastMonth ({ year, month }) {
  let lastYear, lastMonth;
  if (Number(month) === 0) {
    lastYear = Number(year) - 1;
    lastMonth = 11;
  } else {
    lastYear = Number(year);
    lastMonth = Number(month) - 1;
  }
  return { lastYear, lastMonth};
}

export function getNextMonth ({ year, month }) {
  let nextYear, nextMonth;
  if (Number(month) < 11) {
    nextYear = Number(year);
    nextMonth = Number(month) + 1;
  } else {
    nextYear = Number(year) + 1;
    nextMonth = 0;
  }
  return { nextYear, nextMonth };
}
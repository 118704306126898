/* global localStorage */

const householdDefaults = {
	user: {
		credit: [],
		loans: [],
		bankAccounts: [],
		investmentAccounts: [],
		incomes: [],
		spendings: []
	},
	flags: {}
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return {
        default: {
          household: householdDefaults
        }
      };
    }
    const state = JSON.parse(serializedState);
    if (Object.keys(state.default).length > 0) {
      return state;
    }
    return {
      ...state,
      default: {
        household: householdDefaults
      }
    };
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // Ignore errors
  }
};

export function clearLoading() {
  return {
    type: 'CLEAR_LOADING'
  };
}

export function setLoading(message) {
  return {
    type: 'SET_LOADING',
    payload: { message }
  };
}
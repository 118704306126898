export default function reduce (prevState, action) {
  if (action.type === 'UPDATE_HOUSEHOLD') {
    const { household: newHousehold = {} } = action.payload;

    return {
      ...prevState,
      household: {
        ...newHousehold,
        updated: new Date().getTime(),
      }
    };
  }
  return prevState;
}
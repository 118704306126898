import 'regenerator-runtime/runtime';
import _ from 'lodash';
import { formatBudget } from '../helpers/budget';

export default function reduce (prevState, action) {
  if ([
    'LOGIN_SUCCESS',
    'HOUSEHOLD_PROJECTION_SUCCESS',
    'GET_USER_SUCCESS',
    'GET_BUDGET_SUCCESS',
    'SYNC_BUDGET_SUCCESS',
  ].includes(action.type)) {
    const {
      moneyFlows,
      transactions,
    } = formatBudget({
      moneyFlows: _.get(action, 'payload.data.moneyFlows', [ ...prevState.moneyFlows ]),
      transactions: _.get(action, 'payload.data.transactions', [ ...prevState.transactions ]),
    });

    return {
      ...prevState,
      moneyFlows,
      transactions,
      accounts: _.get(action, 'payload.data.accounts', prevState.accounts)
    };
  }

  if ([
    'LOGOUT_SUCCESS'
  ].includes(action.type)) {
    return {
      ...prevState,
      moneyFlows: [],
      transactions: [],
      accounts: []
    };
  }
  return prevState;
}

// thunk function
// export async function getBudgetSpreadsheet(dispatch, getState) {
//   // await docInit();
//   dispatch({ type: 'GET_BUDGET', payload: { budget: { test: '' } } });
// }
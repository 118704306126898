import _ from 'lodash';
import { formatBudget } from '../helpers/budget';

export default function reduce (prevState, action) {
  if ([
    'ADD_TRANSACTION',
    'UPDATE_TRANSACTION',
    'DELETE_TRANSACTION'
  ].includes(action.type)) {
    return {
      ...prevState,
      loading: {
        message: 'Saving changes...'
      }
    };
  }

  if ([
    'ADD_TRANSACTION_SUCCESS',
    'UPDATE_TRANSACTION_SUCCESS',
    'DELETE_TRANSACTION_SUCCESS'
  ].includes(action.type)) {
    const {
      moneyFlows,
      transactions
    } = formatBudget({
      moneyFlows: _.get(action, 'payload.data.moneyFlows', [ ...prevState.moneyFlows ]),
      transactions: _.get(action, 'payload.data.transactions', [ ...prevState.transactions ]),
    });

    return {
      ...prevState,
      loading: null,
      moneyFlows,
      transactions,
    };
  }

  if ([
    'GET_PREVIOUS_MONTH_TRANSACTIONS'
  ].includes(action.type)) {
    return {
      ...prevState,
      loading: {
        message: 'Updating..'
      }
    };
  }

  if ([
    'GET_PREVIOUS_MONTH_TRANSACTIONS_SUCCESS'
  ].includes(action.type)) {
    const transactionsMap = _.get(action, 'payload.data.previous.transactionsMap', {});

    return {
      ...prevState,
      loading: null,
      previous: {
        transactionsMap,
        timestamp: new Date()
      }
    };
  }

  return prevState;
}

// thunk function
// export async function getBudgetSpreadsheet(dispatch, getState) {
//   // await docInit();
//   dispatch({ type: 'GET_BUDGET', payload: { budget: { test: '' } } });
// }
const reducers = [
  require('./projection').default,
  require('./household').default,
  require('./moneyflows').default,
  require('./account').default,
  require('./time').default,
  require('./auth').default,
  require('./budget').default,
  require('./error').default,
  require('./transactions').default,
  require('./loading').default,
  require('./editing').default
];

export default function reduce (prevState, action) {
  const reducer = (state, currentFn) => currentFn(state, action);

  return reducers.reduce(reducer, { ...prevState });
}

export const BANK_ACCOUNT_TYPES = {
  checking: 'checkings',
  savings: 'savings',
  cd: 'cd',
  moneyMarket: 'money market'
};

export const DEBT_TYPES = {
  credit: 'credit',
  loan: 'loan',
};

export const TASK_TYPES = {
  updateAccountBalance: 'updateAccountBalance',
};

export const INVESTMENT_ACCOUNT_TYPES = {
  ira: 'Traditional IRA',
  roth401k: 'Roth 401k',
  rothIra: 'Roth IRA',
  _401a: '401a',
  _401k: '401k',
  brokerage: 'brokerage',
  ugma: 'ugma',
  utma: 'utma'
};

export const ACCOUNT_TYPES = {
  debt: 'debt',
  savings: 'savings',
  investment: 'investment',
};

export const LOAN_TYPES = {
  personal: 'personal loan'
};

export const CREDIT_CARD_TYPES = {
  credit: 'credit'
};

export const INCOME_TYPES = {
  salary: 'salary',
  unemployment: 'unemployment'
  // additional: 'additional',
  // socialSecurity: 'ss'
};

export const SCENARIO_EVENT_TYPES = {
  jobLoss: 'job-loss',
  salaryChange: 'salary-change',
  oneTimeExpense: 'one-time-expense',
  openLoan: 'open-loan',
  adjustContributions: 'adjust-contributions',
  spendingChange: 'Spending Change'
};

export const SCENARIO_LABELS = {
  jobLoss: 'Job Loss',
  salaryChange: 'Salary Change',
  adjustContributions: 'Adjust Contributions',
  oneTimeExpense: 'One Time Expense',
  spendingChange: 'Spending Change'
};

export const SPENDING_TYPES = {
  bill: 'bill',
  medicalExpense: 'medical',
  homePurchase: 'home_purchase',
  loanPay: 'loan_pay'
};

export const FREQUENCY = {
  annually: 'annually',
  monthly: 'monthly',
  weekly: 'weekly',
  biMonthly: 'bi-monthly'
};

// onboarding h
export const keys = {
  loan: 'loans',
  credit: 'credit',
  bank: 'bankAccounts',
  investment: 'investmentAccounts',
  flags: 'flags',
  spending: 'spending',
  income: 'income',
  budget: 'budget',
  debt: 'debt',
  savings: 'savings'
};

export const MONEY_FLOW_TYPES = {
  spending: 'spending',
  income: 'income',
  loanPay: 'loan_pay'
};

export const allStateProvinces = [
  'AL',
  'AZ',
  'AK',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];
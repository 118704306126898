import _ from 'lodash';

export default function reduce (prevState, action) {
  if (['HOUSEHOLD_PROJECTION'].includes(action.type)) {
    return {
      ...prevState,
      loading: {
        message: 'Loading Projection'
      }
    };
  }

  if ([
    'UPDATE_FLAGS_SUCCESS'
  ].includes(action.type)) {
    const projection = _.get(action.payload, 'data.projection', null);
    const flags = _.get(action.payload, 'data.flags', {});

    return {
      ...prevState,
      loading: null,
      flags,
      projection: projection ? {
        ...projection,
        timestamp: new Date().getTime()
      } : prevState.projection,
    };
  }

  if ([
    'LOGIN_SUCCESS',
    'HOUSEHOLD_PROJECTION_SUCCESS',
    'GET_USER_SUCCESS',
    'SYNC_BUDGET_SUCCESS'
  ].includes(action.type)) {
    const projection = _.get(action.payload, 'data.projection', null);
    const household = _.get(action.payload, 'data.household', {});
    const flags = _.get(action.payload, 'data.flags', {});

    return {
      ...prevState,
      loading: null,
      flags,
      household,
      projection: projection ? {
        ...projection,
        timestamp: new Date().getTime()
      } : prevState.projection,
      // budget stuff in budget reducer
    };
  }

  if ([
    'LOGOUT_SUCCESS',
    'LOGIN_FAIL',
    'AUTH_INVALID',
    // 'GET_USER_FAIL'
  ].includes(action.type)) {
    return {
      ...prevState,
      loading: null,
      projection: {},
      flags: {},
      household: {},
      moneyFlows: [],
      transactions: [],
      tasks: [],
      accounts: [],
      user: {
        loggedIn: false
      }
    };
  }

  return prevState;
}
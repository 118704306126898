import { pathOr } from "ramda";
import shortid from 'shortid';

export function itemsInArray (obj, path = []) {
  const items = pathOr(0, path, obj);
  return items > 0;
}

export function copyObject (obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function objHasChanged (current, previous) {
  return JSON.stringify(current) !== JSON.stringify(previous);
}

export function generateShadowKey (items) {
  return items.map(item => {
    if (item.shadowKey) {
      return { ...item };
    }
    return { ...item, shadowKey: shortid.generate() };
  });
}

export function getPlural(word, wordPlural, item) {
  return item.length > 1 ? wordPlural : word;
}

export function createMapFromArray(arr, key) {
  return arr.reduce((map, a) => {
    const mapKey = a[key];
    map[mapKey] = a;
    return map;
  }, {});
}

export function getQueryParam(props, search) {
  if (search) {
    const searchParams = (props.location.search || '').split('?')[1];
    if (searchParams) {
      const params = new URLSearchParams(searchParams);
      return params.get(search);
    }
  }
  return '';
}
export default function reduce (prevState, action) {
  if (action.type.includes('_FAIL') &&
  ![
    'LOGOUT_SUCCESS',
    'LOGIN_FAIL',
    'AUTH_INVALID',
    'GET_USER_FAIL'
  ].includes(action.type)
  ) {
    return {
      ...prevState,
      loading: null,
      error: action.error
        ? action.error
        : { message: 'unknown error' }
    };
  }

  if (action.type.includes('_FAIL')) {
    return {
      ...prevState,
      loading: null
    };
  }

  if (action.type === 'SET_ERROR') {
    return {
      ...prevState,
      error: action.payload.error
    };
  }

  if (action.type === 'CLEAR_ERROR') {
    return { ...prevState, error: null };
  }
  return prevState;
}
export default function reduce (prevState, action) {

  if (action.type === 'SET_CURRENTLY_EDITING') {
    return {
      ...prevState,
      currentlyEditing: {
        ...prevState.currentlyEditing,
        [action.payload.key]: action.payload.id
      }
    };
  }

  return prevState;
}
module.exports = {
  environment: 'production',
  apiBaseURL: 'https://api.caniafford.to',
  plaidClientId: '5effff2900f5020011f9c216',
  plaidPublicKey: '1f7f8afda94befbc8b6cad154c4771',
  plaidSecret: '3240f72f89d4d1dff846ded2857c36',
  googleServiceAccountEmail: 'beta-tracker@scientist-salarian.iam.gserviceaccount.com',
  googlePrivateKey: '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC3ulaCAVGBCQPi\nc8YGKaO4SQ+sp7o8AH8mxSvbkmztWJMjPB/OxL40lbl9wKXenJS5HmfScqrFHQT/\n3Ho8xrKpzTetPRCBMzRB0B7ZDPBulFg7k8RrbqPa0XCaAK4KKt8fSslYrHoUUmsl\n7TC6lbh54FMcboSSE91CXjBfXKVWA7BBkMhfI4EUqNlCCsDaE4TjYNlD9k8l6bD7\n9eUTA0qkpMl0j0FJINqo0PoWPhTsU05SG3isoa4TVFBC2mmdXKUqadJ07PLAKITL\n1PozS8gCtmRGTliQ4m4B7JS7hUfaYsGIj1ubrK5tBHTi4lRo6B+VuAWSiZHqs3qC\n/CHxDjVlAgMBAAECggEANOV4p5hik2B5llaXwkl1wgaC9UYbF9Bay1pELMTlCJaS\nUjgqvjilvHUJARwYhVWWeg+3e38ZDIQ03sdnCB167elQKNfmaxRHbNVTNFGF3Yhn\nOMvr5rRlh4f6P9SHRxUdxSAehSGvSi+KetJlz6pwgOJle0+Uaow+jS+aBLKjRXZu\n/XePRCScYbGG96T3LrW3oztfxDInlAjwQw+ldShluoBysMXfrHBzYmgHj/oceyRa\nab0RCD5UyOJecPA65dKz1vOmZMEca5YXVH5OygzbgyeKRGU73uM3aHEv3Mj8CK0B\nBvzBsOGYAcIzksGS37/jj4Q54Ej6WrhSrstXTsgptwKBgQD9jlgT16DrcK5pMqwr\nFu41Lo9rWchQK4tICtogF6HTRmog0JlDqb/4FrxhLBNT1gwhHh2Fmh+iMe4q4FiR\n2HBgRyaiuRUx5/GuYmJvbjiUbzo62g/mekldsA9ax3UHSSTB12v6Omo3Jn6Lsb+T\n1284Cwr24f80jejalxz8uyPnqwKBgQC5f7DuYrPxbYvd04YVJV6hM2IWRGe6qaJ3\nuwJLyspWprYpcPySXBoVwZvflNaN65OIwYQIxrbrPZ9iHa0vzUJD2QThIZmAunCi\n+CELZjVdIir1+3Fon4xFtMHLnYgQMKB7r60DrGuSCGMzVSRipicKS94leC0JxRcE\nr63CCWMHLwKBgQDTzoaw/J2GT7jCU/wSerM+ZciUqol0vws4gSg5SsR50wZwpxCA\nv1qHiaaPndv/Wn0DZT7QDjGCsib8VjBmlOE66/TQVb/o0pLHU5Ra9T36cPe5ldNW\neqyywJHzCU8vaOB1tNiUTRvROizX0QHyJDLaJA9isOMZKbpsb3Ndg464xQKBgDXE\nYVB3o5izG/tsOE0gy9EFx8SUh0x/puv1dLDrUsLTB7HzYgI6WAU+XSPtTFNOmTDm\nhpLUpmiBMBWMbIqSgEaSPOvUhV3erI9QRKO9OHKKWNF71F9y/4Gk5xdRzgKDVcEE\nJq4N6BAoBjj20NkXN/GwE+/gaY2PZyCgm0jG18FZAoGAUmAGRfPETnXzebbky9z3\n7sUPZu5uGekTXrMtt6samtsUHt0Cylb3rLkEKXnzDgZ/nvBQK8nRpa6cXXEfFzyY\noO46N1ghzR9lxeYfeDlSSt8hxsMiOkGkfws5sWYTrwdDLr5IVQD5vUFZOXNfvgz5\nMCeQnaoH/b85zbmBQJzCNug=\n-----END PRIVATE KEY-----\n'

};

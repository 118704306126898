import { MONEY_FLOW_TYPES } from '../constants';
import _ from 'lodash';
import { startOfMonth, endOfMonth, isAfter, isBefore } from 'date-fns';
import { createMapFromArray } from './utils';

export function formatFlow (flow, spent, flowMap = {}) {
  const today = new Date();
  const monthStart = startOfMonth(today);
  const monthEnd = endOfMonth(today);

  const startDate = _.get(flow, 'metadata.startDate', null);
  const endDate = _.get(flow, 'metadata.endDate', null);
  let pastPresentFuture;
  if (!startDate && !endDate) {
    pastPresentFuture = 'present';
  } else if (isAfter(new Date(startDate), monthEnd)) {
    pastPresentFuture = 'future';
  } else if (isBefore(new Date(endDate), monthStart)) {
    pastPresentFuture = 'past';
  }
  const formatted = {
    ...flow,
    pastPresentFuture
  };
  formatted.spent = spent;
  flowMap[formatted.id] = formatted;
  return formatted;
}

// updates money flows when account changes
// e.g. if debt account is deleted
// or investment account contribution changes
export function updateMoneyFlowsWithoutUpdatingTransactions(unlinkedMoneyFlows, prevMoneyFlows) {
  const map = createMapFromArray(unlinkedMoneyFlows, 'id');
  return prevMoneyFlows.map(f => {
    const flow = { ...f };
    if (map[flow.id]) {
      return formatFlow(map[flow.id], flow.spent);
    }
    return flow;
  });
}

// unlinks transactions (flow is deleted)
export function unlinkTransactions(unlinkedTransactions, prevTransactions) {
  const map = createMapFromArray(unlinkedTransactions, 'id');
  return prevTransactions.map(t => {
    const transaction = {...t};
    if (map[transaction.id]) {
      transaction.category = 'uncategorized';
      transaction.type = MONEY_FLOW_TYPES.spending;
      transaction.moneyFlowId = null;
    }
    return transaction;
  });
}

// this updates money flow DATA but not spent / transaction amount
// this will also add newly created flows
export function updateMoneyFlowsState({
  updatedMoneyFlows,
  prevMoneyFlows,
  prevTransactions
}) {
  const updatedFlowsMap = createMapFromArray(updatedMoneyFlows, 'id');
  const transactions = prevTransactions.map(t => {
    const transaction = { ...t };
    const updatedFlow = updatedFlowsMap[transaction.moneyFlowId];
    if (updatedFlow) {
      transaction.category = updatedFlow.category;
      transaction.type = updatedFlow.type;
    }
    return transaction;
  });

  const moneyFlows = prevMoneyFlows.map(flow => {
    const updatedFlow = updatedFlowsMap[flow.id];
    if (updatedFlow) {
      const formattedFlow = formatFlow(updatedFlow, flow.spent, updatedFlowsMap);
      delete updatedFlowsMap[flow.id];
      return formattedFlow;
    }
    return flow;
  });

  // if there are leftover money flows, they're new
  // add them to the top
  const newMoneyFlows = Object.values(updatedFlowsMap).map(updatedFlow => {
    return formatFlow(updatedFlow, 0, updatedFlowsMap);
  });

  return {
    moneyFlows: [
      ...newMoneyFlows,
      ...moneyFlows,
    ],
    transactions,
  };
}


// this is from SCRATCH
export function formatBudget({ moneyFlows = [], transactions = [] }) {
  // money flow map from ID to flow
  const moneyFlowsMap = createMapFromArray(moneyFlows, 'id');

  const currentMoneyFlows = [];
  moneyFlows.forEach(flow => {
    currentMoneyFlows.push(formatFlow(flow, 0, moneyFlowsMap));
  });

  // format transactions
  const formattedTransactions = transactions.map(transaction => {
    const formattedTransaction = { ...transaction };

    formattedTransaction.timestamp = formattedTransaction.timestamp
      ? formattedTransaction.timestamp
      : formattedTransaction.createdAt;

    // update flow spending
    const flow = moneyFlowsMap[formattedTransaction.moneyFlowId];
    if (flow) {
      flow.spent += formattedTransaction.amount;
  
      // update type and category on transaction
      formattedTransaction.category = flow.category;
      formattedTransaction.type = flow.type;
    } else {
      // if no flow, set to uncategorized
      formattedTransaction.category = 'uncategorized';
      formattedTransaction.type = MONEY_FLOW_TYPES.spending;
    }
    return formattedTransaction;
  });

  return {
    moneyFlows: currentMoneyFlows,
    moneyFlowsMap,
    transactions: formattedTransactions
  };
}

const colors = {
  rainbowOneTheme: {
    charcoal: '#264653', // charcoal
    persianGreen: '#2A9D8F', // persian green
    orangeYellowCrayola: '#E9C46A', // orange yellow crayola
    sandyBrown: '#F4A261', // sandy brown
    burntSienna: '#E76F51' // burnt sienna
  },
  neonTheme: {
    muted: {
      green: '#99EDCC',
      blue: '#08B2E3',
      yellow: '#fbffc7',
      yellowBright: '#FCFF6C',
      magenta: '#BF1363',
      lightPurple: '#F8F1FF',
      darkTeal: '#006992',
      pink: '#FF499E',
      brightPink: "#FE5D9F",
      blueMute: '#C9E4E7',

      darkRed: '#CE4760',
      nude: '#FFCAB1',
      orange: '#F39237',
      darkerTeal: '#0F5257',
      purple: '#820B8A'
    },
    chartreuse: '#7fff00',
    yellow: '#ffff00',
    cyan: '#01BAEF',
    pink: '#ff3db1',
    purple: '#C724B1',
    aquamarine: '#7fffd4',
    aqua: '#00FFFF',
    lightYellow: '#ffffed'
  },
  neonStatusColors: {
    deepRed: '#be0000',
    red: '#ED0800',
    orange: '#FFA500',
    yellow: '#FFFF00',
  },
  neonAlternatingBackgrounds: {
    aquamarine: '#7fffd4',
    lightYellow: '#fbffc7',
    lightGreen: '#d9ffe0',
    aqua: '#3EFCFF',
    lightBlue: '#89F2FF'
  },
  coldThemeOne: {
    richBlack: '#0A090C',
    isabellineWhite: '#F0EDEE',
    darkTeal: '#07393C',
    teal: '#2C666E',
    middleBlue: '#90DDF0'
  },
  warmThemeOne: {
    richBlack: '#020202',
    beige: '#F2F3D9',
    lavender: '#BFB1C1',
    tyrianPurple: '#5E0035',
    raspberry: '#950952'
  },
  warmColdThemeOne: {
    rubyRed: '#A31621',
    amaranthRed: '#DB222A',
    beauBlue: '#BFDBF7',
    indigoDye: '#053C5E',
    metallicSeaweed: '#1F7A8C'
  },
  chartThemeOne: {
    darkBlue: '#173F5F',
    blue: '#20639B',
    teal: '#3CAEA3',
    yellow: '#F6D55C',
    red: '#ED553B'
  },
  chartThemeTwo: {
    darkBlue: '#006992',
    blue: '#08B2E3',
    teal: '#99EDCC', // green
    yellow: '#fbffc7',
    red: '#FF499E'
  },
  /* Rainbow */
  DarkGreen: '#066350',
  MediumGreen: '#02871F',
  LightGreen: '#80D8C6',
  LightBlue: '#2E46B6',
  DarkBlue: '#1F3263',
  Purple: '#503750',
  Yellow: '#FEC96C',
  Orange: '#C76D59',
  Red: '#F0655D',

  /* Grayscale */
  Black: '#000000',
  White: '#FFFFFF',
  Gray1: '#333333',
  Gray2: '#6B6B6B',
  Gray3: '#ABABAB',
  Gray4: '#CCCCCC',
  Gray5: '#DCDCDC',
  Gray6: '#EEEEEE',
  Gray7: '#FAFAFA',
  Transparent: {
    Black80: 'rgba(0,0,0,0.8)',
    Black58: 'rgba(0,0,0,0.58)',
    Black33: 'rgba(0,0,0,0.33)',
    Black20: 'rgba(0,0,0,0.2)',
    Black14: 'rgba(0,0,0,0.14)',
    Black07: 'rgba(0,0,0,0.07)',
    White20: 'rgba(255,255,255,0.20)',
    White42: 'rgba(255,255,255,0.42)',
    White67: 'rgba(255,255,255,0.67)',
    White80: 'rgba(255,255,255,0.80)',
    White86: 'rgba(255,255,255,0.86)',
    White93: 'rgba(255,255,255,0.93)',
    White98: 'rgba(255,255,255,0.98)'
  }
};

export default colors;

/**
 * https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
 * @param {Function} fn
 * @param {boolean} suppressError
 * @param {number} retriesLeft
 * @param {number} interval
 */

export function retry (fn, suppressError = false, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            if (suppressError) {
              // sends back empty component
              resolve({ default: () => null });
            } else {
              reject(error);
            }
            return;
          }

          retry(fn, suppressError, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

import React from 'react';
import theme from 'src/ui/styles/theme';
import styled from 'react-emotion';
import ReactLoading from 'react-loading';

const LoadingFallback = (props) => (
  <Wrapper {...props} >
    <ReactLoading type={'spokes'} color={theme.colors.coldThemeOne.teal} height={'20%'} width={'20%'} />
  </Wrapper>
);

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  ${p => p.minHeight ? `min-height: ${p.minHeight};`: ''}
`;

export default LoadingFallback;
